* {
  &:active,
  :focus {
    outline: none !important;  // 1
  }
}

/*a:not(.mat-button):not(.mat-icon-button):not(.mat-raised-button):not(.mat-stroked-button):not(.mat-fab):not(.mat-mini-fab):not([mat-list-item]) {
  color: var(--primary-color);
}*/

html, body {
  height: 100%;
  width: 100%;
}

a {
   text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
