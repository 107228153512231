/*$custom-typography: mat.define-typography-config($font-family: '"Muli", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif');
@include mat.core($custom-typography);

// default theme:
$primary: mat.define-palette(mat.$blue-palette, 900);
$accent: mat.define-palette(mat.$gray-palette, 900);
$error: mat.define-palette(mat.$red-palette, 900);
$theme: mat.define-light-theme($primary, $accent, $error);

@include mat.all-component-themes($theme);

// "dark" theme:
$dark-p: mat.define-palette(mat.$blue-grey-palette, 500);
$dark-a: mat.define-palette(mat.$blue-grey-palette, 900);
$dark-t: mat.define-dark-theme($dark-p, $dark-a);

.darkTheme {
  @include mat.all-component-themes($dark-t);
}*/
@use '@angular/material' as mat;

/**
* Generated theme by Material Theme Generator
* https://material-theme-generator.travetto.io
*/

@import '@angular/material/theming';

$font-family: 'Poppins', sans-serif;

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, $font-family, -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, $font-family, -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, $font-family, 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, $font-family, 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, $font-family, 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, $font-family, 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, $font-family, 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, $font-family, 0.0067em),
  body-2: mat.define-typography-level(12px, 24px, 500, $font-family, 0.0179em),
  body-1: mat.define-typography-level(12px, 20px, 400, $font-family, 0.0179em),
  button: mat.define-typography-level(15px, 15px, 400, $font-family, 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, $font-family, 0.0333em),
  input: mat.define-typography-level(14px, 1.125, 400, $font-family, 1.5px)
);

@include mat.typography-hierarchy($fontConfig);
@include mat.checkbox-typography($fontConfig);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: rgba($light-text, 0.95);
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);


// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

/*mat.$light-theme-background-palette: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);*/

@include mat.core($fontConfig);

body {
  --primary-color: rgba(75, 179, 156, 1);
  --primary-color-rgb: 75, 179, 156;
  --primary-lighter-color: rgba(75, 179, 156, 8);
  --primary-darker-color: #296356;
  --text-primary-color: #{$light-text};
  --text-primary-lighter-color: #{$light-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  --font-family: #{$font-family};
}




@mixin mat-design-icon-mixin() {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  /* Rules for sizing the icon. */
  &.md-18 { font-size: 18px; }
  &.md-24 { font-size: 24px; }
  &.md-36 { font-size: 36px; }
  &.md-48 { font-size: 48px; }

  /* Rules for using icons as black on a light background. */
  &.md-dark {
    color: rgba(0, 0, 0, 0.54);

    &.md-inactive { color: rgba(0, 0, 0, 0.26); }
  }

  /* Rules for using icons as white on a dark background. */
  &.md-light {
    color: rgba(255, 255, 255, 1);

    &.md-inactive { color: rgba(255, 255, 255, 0.3); }
  }
}






$mat-primary: (
  main: rgba(75, 179, 156, 1),
  lighter: #fff,
  darker: #419b87,
  200: #419b87, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: #B99462;
  --accent-lighter-color: #c0c2c5;
  --accent-darker-color: #1c1f28;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #B99462,
  lighter: #c0c2c5,
  darker: #1c1f28,
  200: #B99462, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: rgba(201, 36, 42, 1);
  --warn-lighter-color: white;
  --warn-darker-color: #a40000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #5A0102,
  lighter: #ebb3b3,
  darker: #a40000,
  200: #bb0000, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
.default-theme {
  @include mat.all-component-themes($theme);
}

@mixin notification-theme($my-theme-info, $my-theme-success, $my-theme-warning, $my-theme-error) {
  .success-notification-overlay,
  .info-notification-overlay,
  .warning-notification-overlay,
  .error-notification-overlay,
  .default-notification-overlay {
    border-left: 4px solid;

    &::before {
      font-family: "Material Icons";
      float: left;
      font-size: 24px;
      // because we want spaces to be considered in content
      // https://stackoverflow.com/questions/5467605/add-a-space-after-an-element-using-after
      white-space: pre;
    }

    /*strong {
      font-weight: 500;
    }*/
  }
  .success-notification-overlay {
    border-left-color: mat.get-color-from-palette($my-theme-success, 400);

    &::before {
      content: "\e86c  ";
      color: mat.get-color-from-palette($my-theme-success, 400);
    }
  }
  .info-notification-overlay {
    border-left-color: mat.get-color-from-palette($my-theme-info, 400);

    &::before {
      content: "\e88e  ";
      color: mat.get-color-from-palette($my-theme-info, 400);
    }
  }
  .default-notification-overlay {
    border-left-color: #fff;

    &::before {
      color: #fff;
    }
  }
  .warning-notification-overlay {
    border-left-color: mat.get-color-from-palette($my-theme-warning, 400);

    &::before {
      color: mat.get-color-from-palette($my-theme-warning, 400);
    }
  }
  .error-notification-overlay {
    border-left-color: mat.get-color-from-palette($my-theme-error, 400);

    &::before {
      color: mat.get-color-from-palette($my-theme-error, 400);
    }
  }
  .warning-notification-overlay::before {
    content: "\e002  ";
  }
  .error-notification-overlay::before {
    content: "\e000  ";
  }
}

@mixin custom-components-theme($theme, $theme-info, $theme-success, $theme-warning, $theme-error) {
  @include notification-theme($theme-info, $theme-success, $theme-warning, $theme-error);
}

$my-theme-info: mat.define-palette(mat.$blue-palette);
$my-theme-success: mat.define-palette(mat.$green-palette);
$my-theme-warning: mat.define-palette(mat.$yellow-palette);
$my-theme-orange-warning: mat.define-palette(mat.$orange-palette);
$my-theme-error: mat.define-palette(mat.$red-palette);

@include custom-components-theme(
    $theme,
    $my-theme-info,
    $my-theme-success,
    $my-theme-warning,
    $my-theme-error
);


$mat-xs: "screen and (max-width: 599px)";
$mat-sm: "screen and (min-width: 600px) and (max-width: 959px)";
$mat-md: "screen and (min-width: 960px) and (max-width: 1279px)";
$mat-lg: "screen and (min-width: 1280px) and (max-width: 1919px)";
$mat-xl: "screen and (min-width: 1920px) and (max-width: 5000px)";
$mat-lt-sm: "screen and (max-width: 599px)";
$mat-lt-md: "screen and (max-width: 959px)";
$mat-lt-lg: "screen and (max-width: 1279px)";
$mat-lt-xl: "screen and (max-width: 1919px)";
$mat-gt-xs: "screen and (min-width: 600px)";
$mat-gt-sm: "screen and (min-width: 960px)";
$mat-gt-md: "screen and (min-width: 1280px)";
$mat-gt-xl: "screen and (min-width: 1920px)";

$theme-margin: 15px;

.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

/*.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 25px 25px 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 25px 0 0 25px !important;
  //flex-grow: 1 !important;
}

.mat-raised-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button {
  border-radius: 25px !important;
}*/


.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.1) !important;
}

.mat-simple-snackbar {
  white-space: pre-wrap;
  min-height: 34px;
  padding-left: 5px;

  .mat-button {
    color: rgba(255, 255, 255, .7) !important;
    font-size: 12px;
  }
}

.mat-accordion .mat-expansion-panel.mat-expanded {
  background-color: rgba(0, 0, 0, .03);
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
  transition: opacity $backdrop-animation-duration $backdrop-animation-timing-function, background $backdrop-animation-duration $backdrop-animation-timing-function;
  opacity: 0;

  &.cdk-overlay-backdrop-showing {
    opacity: 1;
  }
}

.mat-dialog-container {
  position: relative;
}

.mat-tab-label {
  min-width: 72px !important;
}

.fade-effect-tabs {
  .mat-tab-body.mat-tab-body-active {
    animation: fade 0.5s;
  }
}


@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hide-tab {
  > .mat-tab-header {
    display: none;
  }
}

.no-first-row-border-table {
  tr {
    &:first-child {
      td {
        border-top: 0 !important;
      }
    }
  }
}

.mat-form-field {
  letter-spacing: unset;
}

.mat-snack-bar-container {
  display:flex !important;
  flex-direction: row;
  align-items: center;

  >div {
    width: 100%;
  }

  &::before {
    margin-right: 10px;
    flex-grow: 0;
  }

  app-snack-bar {
    flex-grow: 1;
  }
}

.one-blink-effect {
  animation-duration: 2200ms;
  animation-name: one-blink-effect;
}

@keyframes one-blink-effect {
  0% {
    background: white;
  }
  70% {
    background: rgba(var(--primary-color-rgb), .3);
  }
  100% {
    background: white;
  }
}

